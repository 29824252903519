import React from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { withI18n } from '../../hocs'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import BoxOtherInfoStyled from './styled'
import _ from 'lodash'
import EditFieldAction from "../../organism/edit-field-action";
import {Panel} from "../index";

const BoxOtherInfo = ({ variableFields, profile, i18n, phase = null }) => {
  if (!variableFields) {
    return null
  }

  if(!_.isArray(variableFields)) {
    variableFields = [variableFields];
  }

  if (_.isArray(variableFields) && variableFields.length && variableFields[0].order) {
    variableFields = variableFields.sort((a, b) => {
      if (a.order > b.order) return 1;
      if (a.order < b.order) return -1;
      return 0;
    });
  }

  const rendeItem = (key) => {
    const item = variableFields[key]
    let text = ''
    const alarmValues = item.alarm_values;
    if (_.isString(item.value) && (
      moment(item.value, 'YYYY-MM-DDTHH:mm:ss.sssZ', true).isValid() ||
      moment(item.value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() ||
      moment(item.value, 'YYYY-MM-DD', true).isValid() 
    )) {
      text = moment(item.value).format('DD/MM/YYYY')
    } else if (_.isArray(item.value)){
      text = item.value.join(", ")
    } else if(_.isObject(item.value)) {
      text = JSON.stringify(item.value);
    }else{
      text = item.value
    }

    let styles = {};
    if (phase) styles = {fontWeight: "normal", fontSize: 14}
    if (alarmValues && _.isArray(alarmValues) && item.value && alarmValues.includes(String(item.value).toLowerCase())) {
      styles.color = "red"
      styles.fontWeight = 600
    }

    return (
      <Grid key={key} lg={12} container item justifyContent='space-between' className={'row-table'}>
        <Grid item >
          <p className={'title'} style={phase ? {fontWeight: 600, fontSize: 14} : {}} dangerouslySetInnerHTML={{ __html: item.title }}></p>
        </Grid>
        <Grid item >
          <p style={styles} className={text.length < 120 ? 'value' : 'value long'}>
            <EditFieldAction remoteName={item.remote_name} defaultValue={text} profile={profile} phase={phase}/>
          </p>
        </Grid>
      </Grid>
    )
  }

  const variableKeys = [];
  for (const key in variableFields) {
    if(variableFields[key] && (variableFields[key].value || variableFields[key].value === 0 || variableFields[key].is_editable)) {
      variableKeys.push(key);
    }
  }

  const halfIdx = Math.ceil((variableKeys.length - 1) / 2);
  const fields1 = variableKeys.slice(0, halfIdx).map(rendeItem);
  const fields2 = variableKeys.slice(halfIdx, variableKeys.length).map(rendeItem);

  return <>
    {phase ?
        <Grid container spacing={6} key={phase}>
          <Grid item xs={12}>
            <Panel title={phase}>
              <Grid container rowSpacing={8} lg={12}
                    style={{flexWrap: 'nowrap', gap: 24}}
                    justifyContent='space-between' direction='row'>
                <Grid id='boxOtherInfo1' container item>
                  {fields1}
                </Grid>
                <Grid id='boxOtherInfo2' container item>
                  {fields2}
                </Grid>
              </Grid>
            </Panel>
          </Grid>
        </Grid>
    :
      variableKeys.length ? (<BoxOtherInfoStyled>
      <div className="header-table">
        <p>
          {i18n.boxOtherInfo.additionalInformation}
          </p>
      </div>
      <Grid container rowSpacing={8}  lg={12} justifyContent='space-between' direction='row'>
        <Grid id='boxOtherInfo1' container item>
          {fields1}
        </Grid>
        <Grid id='boxOtherInfo2' container item>
          {fields2}
        </Grid>
      </Grid>
    </BoxOtherInfoStyled>) : null
    }
    </>
}

BoxOtherInfo.propTypes = {
  i18n: PropTypes.object.isRequired,
  variableFields: PropTypes.object
}

const enharce = compose(withI18n)

export default enharce(BoxOtherInfo)
