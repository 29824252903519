import React, { useState, useEffect } from 'react'
import EvaluativeTestsStyled from './styled';
import {SECTION_KEYS} from '../../../config/constants'
import { Box, Grid } from '@material-ui/core'
import { CustomAlert, PageTitle } from '../../atoms'
import { useAppContext } from '../../../context/appContext'
import { LinearProgress } from '@material-ui/core';
import { useMutation } from "@tanstack/react-query";
import { deleteEvaluativeTest, duplicateEvaluativeTest } from '../../../api/index'
import { CircularProgress } from '@material-ui/core';
import RegularButton from '../../atoms/regular-button';
import Divider from '@material-ui/core/Divider';
import moment from 'moment'
import DateRangePiker from '../../molecules/date-range-picker';
import routes from "../../../config/routes";
import CardEvaluativeTests from '../../molecules/card-evaluative-tests';
import { useEvaluativeTests } from '../../../api/queries/useEvaluativeTests';
import { FormControlLabel, Switch } from '@material-ui/core';
import InputField from '../../atoms/input-field'
import _ from 'lodash'

const EvaluativeTests = ({i18n}) => {
  const { module } = useAppContext()
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(30, 'days'));
  const [focused, setFocused] = useState(null);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
  const [noResultsAlert, setNoResultsAlert] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isDuplicateSuccess, setIsDuplicateSuccess] = useState(false);
  const [isActiveTests, setIsActiveTests] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDateDeleted, setIsDateDeleted] = useState(false);

  const removeEvaluativeTest = async (id) => {
    try {
      return await deleteEvaluativeTest(id);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error', error);
      throw error;
    }
  }

  const cloneEvaluativeTest = async (id) => {
      return await duplicateEvaluativeTest(id);
  }

  const mutationDuplicateEvaluativeTest = useMutation(cloneEvaluativeTest);
  const mutationDeleteEvaluativeTest = useMutation(removeEvaluativeTest);

  const defaultParams = {
    "active": isActiveTests,
    "initDate": moment(startDate).format('YYYY-MM-DD'),
    "endDate": moment(endDate).format('YYYY-MM-DD'),
    "term": searchTerm
  }
  const [queryParams, setQueryParams] = useState(defaultParams);

  const { data: evaluativeTests, isLoading, isError, refetch } = useEvaluativeTests(module, queryParams);

  const isTestInactive = (test) => {
    const currentDate = moment();
    const endDate = moment(test.end_date);
    return endDate.isBefore(currentDate);
  };

  const handleToggleActiveTests = () => {
    setIsActiveTests((prevIsActive) => !prevIsActive);
  };

  useEffect(() => {
    performFilterRequest(startDate, endDate);
  }, [isActiveTests, startDate, endDate, searchTerm]);

  useEffect(() => {
    const fetchDataAndShowAlerts = async () => {
      try {

        setTimeout(() => {
          setIsDeleteSuccess(false);
          setIsDuplicateSuccess(false);

        }, 2000);
        await refetch();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error al recargar los datos:', error);
      }
    };

    if (isDeleteSuccess || isDuplicateSuccess) {
      fetchDataAndShowAlerts();
    }

  }, [isDeleteSuccess, isDuplicateSuccess, refetch]);

  useEffect(() => {
    if (evaluativeTests && evaluativeTests.length === 0) {
      setNoResultsAlert(true);
    } else {
      setNoResultsAlert(false);
    }
  }, [evaluativeTests]);


  if (isError) {
    return <CustomAlert severity="error" message={i18n.evaluativeTests.errorFetchTests} />
  }

  const handleEdit = (selectedTestId) => {
    window.location.href = `/${module}/${SECTION_KEYS.EVALUATIVE_TESTS}/test/${selectedTestId}`
  };

  const handleDuplicate = async (test) => {
    const { id } = test;
    try {
      setIsDuplicating(true);
      await mutationDuplicateEvaluativeTest.mutateAsync(id);

      setIsDuplicateSuccess(true);
      setIsDuplicating(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error al duplicar la prueba:', error);
      setIsDuplicating(false);
    }
  };

  const handleDelete = async (test) => {
    const { id } = test;
    try {
      setIsRemoving(true);
      await mutationDeleteEvaluativeTest.mutateAsync(id);

      setIsRemoving(false);
      setIsDeleteSuccess(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error:', error);
      setIsRemoving(false);
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    performFilterRequest(date, endDate);
    setIsDateDeleted(false);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    performFilterRequest(startDate, date);
    setIsDateDeleted(false);
  };

  const performFilterRequest = (startDate, endDate) => {
    if (startDate && endDate && !isDateDeleted) {
      const params = {
        "active": isActiveTests,
        "initDate": moment(startDate).format('YYYY-MM-DD'),
        "endDate": moment(endDate).format('YYYY-MM-DD'),
        "term": searchTerm
      };
      setQueryParams(params);
    }
  };

  const handleSearchTerm = _.debounce((value) => {
    setSearchTerm(value.trim());
  }, 250);

  return (<>
      {isLoading && <LinearProgress />}
      <EvaluativeTestsStyled>
        <Grid container className="title-container" justifyContent='space-between' alignItems='center'>
          <Grid item xs={12} sm={6} className="title" >
            <PageTitle title={i18n.evaluativeTests.title} />
          </Grid>
          <a href={routes.createEvaluativeTests}>
            <RegularButton title={i18n.evaluativeTests.buttonCreateTest} className="button-custom" />
          </a>
        </Grid>
        <Divider variant='inset' />
        <Grid container className='date-container' justifyContent='space-between' alignItems='center'>
          <Grid item xs={12} sm={4}>
            <span className="description-section">{i18n.evaluativeTests.descriptionSection}</span>
          </Grid>
          <Grid item xs={12} sm={8} className="buttons-wrapper" >
            <FormControlLabel
              control={<Switch checked={isActiveTests} onChange={handleToggleActiveTests} />}
              label={isActiveTests ? i18n.evaluativeTests.activesTest : i18n.evaluativeTests.inactivesTest}
            />
            <DateRangePiker
              startDate={startDate}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              endDate={endDate}
              onFocusChange={({ focused }) => setFocused(focused)}
              focused={focused}
              showButton={false}
            />

            <Box sx={{ pl:3, display: 'inline-flex', maxWidth: "250px" }}>
              <InputField
                placeholder={i18n.evaluativeTests.placeholderInputTerm}
                onChange={(e) => { e.persist(); handleSearchTerm(e.target.value) }} />
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent='center'>
          <div className="alert-container">
            {isDuplicating &&
              <CircularProgress />
            }
            {isRemoving &&
              <CircularProgress />}
            {isDeleteSuccess && (
              <CustomAlert severity="success" message={i18n.evaluativeTests.successDeleteTest} />
            )}
            {isDuplicateSuccess && (
              <CustomAlert severity="success" message={i18n.evaluativeTests.successDuplicateTest} />
            )}
          </div>
        </Grid>
        <Grid item className="cards-wrapper">
          {evaluativeTests && (
            evaluativeTests.map((test) => (
              <CardEvaluativeTests
                key={test.id}
                pretitle={test.evaluative_config_template ? test.evaluative_config_template.name : ""}
                title={test.name}
                rangeDate={`${test.init_date} - ${test.end_date}`}
                quantityQuestions={`${test.questions ? test.questions.length : 0} ${test.questions && test.questions.length === 1
                  ? i18n.evaluativeTests.singleQuestion
                  : i18n.evaluativeTests.multipleQuestions
                  }`}
                urlBot={test.bot_url}
                testId={test.id}
                onEdit={() => {
                  handleEdit(test.id, test);
                }}
                onDuplicate={() => handleDuplicate(test)}
                onDelete={() => handleDelete(test)}
                isInactive={isTestInactive(test)}
                statusCard={isTestInactive(test) ? i18n.evaluativeTests.inactiveCard : ""}
              />
            ))
          )}
          <Grid container justifyContent="center" alignContent="center">
            {noResultsAlert && <CustomAlert severity="info" message={i18n.evaluativeTests.noResults} />}
          </Grid>
        </Grid>
      </EvaluativeTestsStyled>
    </>
  )
}

export default EvaluativeTests
