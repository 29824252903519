/* eslint-disable react/display-name */
import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import _ from 'lodash'
import moment from 'moment'
import {
  CandidatureText,
  FavoritesTableStyled,
  Grow,
  LimitedDefaultCol,
  MTLinearPercentStyled,
  OfferStateImageStyled,
  SearchCandidatureName,
  SubtextStyled
} from './styled'
import {
  FavouriteIcon,
  MTCircularPercent,
  MTExporterButton,
  MTImage,
  MTText,
  MTToolbar,
  MTVideo,
} from '../../atoms'
import Tag from '../../atoms/tag/index'
import * as api from "../../../api/index";
import {ModalAnnouncement, ModalChangeStatus, OfferStateImage, TableFilters} from '../../molecules'
import {INTEGRATIONS, MODULES, ROLES, QUERY_KEYS} from '../../../config/constants'
import StatusGroupType from '../../../models/status-group-type'
import ModalChangeTags from "../../molecules/modal-change-tags";
import {deletePushOffer, getRange, postOrPatchCitations} from "../../../api";
import ModalMailCustom from "../../molecules/modal-send-mail-custom";
import {Badge, Grid} from '@material-ui/core'
import {AuthUtils} from "../../../redux/auth";
import routes from "../../../config/routes";
import offerCandidatureStatusType from "../../../models/offer-candidature-status-type";
import {searchCurrentOfferHasCandidature} from "../../../utils/offer";
import ModalRequireDoc from "../../molecules/modal-require-doc";
import {matchPath, useParams} from "react-router";
import {StatusType} from "../../../models";
import OfferCandidatureStatusType from "../../../models/offer-candidature-status-type";
import autoStatusDomains from "../../../utils/auto-status-domains";
import statusOptions from "../../../utils/status-options";
import { FeatureGuard } from '../../../utils/guard'
import { useFunnelStatusTypes } from '../../../api/queries/useFunnelStatusTypes'
import { iso8601Format } from '../../../utils/date'
import { useMutation } from '@tanstack/react-query'
import { invalidateQueriesByKeys, refetchQueriesByKeys } from '../../../api/mutations/mutationCallbacks'

const getReadClass = rowData => (_.get(rowData, 'was_read', false) ? 'was_read' : 'not_read')

const CustomTable = ({
                       initInbox,
                       filters,
                       saveSearchData,
                       defaultFilters,
                       filterGroupSelected,
                       data,
                       statusTypes,
                       manualStatusTypes,
                       total,
                       rowsSelected,
                       setRowsSelected,
                       history,
                       unmarkAsRead,
                       postFavorites,
                       requireDocumentation,
                       markAsRead,
                       changeStatus,
                       changeTags,
                       setFilterGroup,
                       i18n,
                       isFetching,
                       showFilterGroup,
                       dataFields,
                       initialDataInbox,
                       fetchProcesses,
                       module,
                       showChangeStatus,
                       client,
                       user_email,
                       auth,
                       actions,
                       tablePage,
                       tableSort,
                       tableSetPage,
                       tableSetSort,
                       tablePerPage,
                       tableSetPerPage,
                       queryOptions,
                       inboxModule,
                       tagsTypes,
                       filtersClear,
                       resetFiltersOnLoad,
                       match,
                       location,
                       sendEmailCustom,
                       notificationMultipleEnabled,
                       offer,
                       saveSearchQuery,
                       setOfferFilterGroupSelected,
                       forceTotal,
                       overrideFilters,
                       ...props
  }) => {
  
  const { data: statusFunnelTypes } = useFunnelStatusTypes(module);
  const mutationUpdateStatesSelectionFunnel = useMutation(api.updateNewStatesSelectionFunnel);

  // clone data
  dataFields = _.cloneDeep(dataFields)

  const isSaveSearchItemPage = matchPath(match.path, {path: routes.savedSearchesItem});
  const isOfferItemPage = matchPath(match.path, {path: routes.offerCandidatures});

  useEffect(() => {
    if (resetFiltersOnLoad && !(module === MODULES.ID_EMPLOYEE_LINE && isSaveSearchItemPage) && !location.search.includes('keepFilters')) {
      filtersClear();
    }
    // eslint-disable-next-line
  }, [])

  const {slug} = useParams();
  const [isOpenMailCustomModal, setOpenMailCustomModal] = React.useState(false)

  const [totalRowsSelected, setTotalRowsSeleted] = useState(0);
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false)
  const [openChangeTagsModal, setOpenChangeTagsModal] = useState(false)
  const [announcement, setAnnouncement] = useState(null)
  const [announcementModal, setAnnouncementModal] = useState(false)
  const [requireDoc, setRequireDoc] = useState(null)
  const [requireDocModal, setRequireDocModal] = useState(false)
  const [lastFilterGroupValue, setLastFilterGroupValue] = useState(-1)

  const page = queryOptions ? queryOptions.page : tablePage
  const sort = queryOptions ? queryOptions.sort : tableSort
  const setPage = queryOptions ? queryOptions.setPage : tableSetPage
  const perPage = queryOptions ? queryOptions.perPage : tablePerPage
  const setSort = queryOptions ? queryOptions.setSort : tableSetSort
  const setPerPage = queryOptions ? queryOptions.setPerPage : tableSetPerPage

  const isReady = inboxModule === module

  let newFilters = {...defaultFilters, ...filters}
  if (!(!filterGroupSelected || filterGroupSelected === -1)) {
    if (module === MODULES.ID_TALENT_ACQUISITION) {
      if (match.path === routes.offerCandidatures) {
        newFilters['offerHasCandidatures.state'] = filterGroupSelected
      } else {
        let statusByGroup = filterGroupSelected ? _.find(StatusGroupType, {id: filterGroupSelected}).status : null;
        if (!_.isEmpty(saveSearchQuery)) {
          let saveSearchQueryStatusFilters = saveSearchQuery.filters && saveSearchQuery.filters.estado ? _.map(saveSearchQuery.filters.estado, 'value') : null;
          newFilters['estado'] = statusByGroup && saveSearchQueryStatusFilters ? saveSearchQueryStatusFilters.filter(val => statusByGroup.indexOf(val) !== -1) : statusByGroup;
        } else {
          newFilters['estado'] = statusByGroup;
        }
      }
    } else {
      newFilters['state.id'] = filterGroupSelected
    }
  }
  if (module === MODULES.ID_ON_BOARDING && overrideFilters) {
    newFilters = overrideFilters;
  }

  let isOnlyReader;
  if (module === MODULES.ID_FEEDBACK) {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_feedback)
  } else if (module === MODULES.ID_EMPLOYEE_LINE) {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_employee_line)
  } else if (module === MODULES.ID_ON_BOARDING) {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_on_board)
  } else if (module === MODULES.ID_EXIT_INTERVIEW) {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_exit_interview)
  } else {
    isOnlyReader = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_talent_acquisition)
  }
  const hasPullFeature = module === MODULES.ID_TALENT_ACQUISITION && FeatureGuard.canAccessToPullOffers();

  const hasFeaturePush = module === MODULES.ID_TALENT_ACQUISITION && FeatureGuard.canAccessToPushOffers()

  if (dataFields && module === MODULES.ID_TALENT_ACQUISITION && match.path === routes.offerCandidatures) {
    dataFields.splice(1, 0, {
      id: -20,
      remote_name: 'offer_has_candidatures.offer.state',
      name: i18n.processesTable.offerCandidatureState
    })
  }

  const getQueryParams = () => {
    return {
      filters: _.cloneDeep(newFilters),
      range: getRange(page, perPage),
      sort
    }
  }

   const [query, setQuery] = useState(saveSearchQuery ? saveSearchQuery : getQueryParams())
 // const [query, setQuery] = useState(getQueryParams());

  const tableRef = useRef(null)

  useEffect(() => {
    setRowsSelected([]);
    if (!matchPath(routes.processesSavedSearches, {path: location.pathname, exact: false})) {
      updateData()
    }

    // eslint-disable-next-line
  }, [query, page, perPage, sort])

  // useEffect(() => {
  //   if (!isSaveSearchItemPage) {
  //     setFilterGroup(-1);
  //   }
  //   // eslint-disable-next-line
  // }, [])

  useEffect(() => {
    updateDataQuery()
  }, [data, total, forceTotal, statusTypes, dataFields])

  useEffect(() => {
    if (isReady) {
      setPage(page)
      updateQueryIfFiltersWasChanged()
    }
    // eslint-disable-next-line
  }, [filters, page, filterGroupSelected])

  useEffect(() => {
    setTotalRowsSeleted(getTotalrowsSelected);
  }, [rowsSelected])// eslint-disable-line react-hooks/exhaustive-deps

 /*
 const initOrFetchInboxOnSaveSearchItemPage = (queryParams, filtersGroups, statusGroupId, isInit = true, filterGroupSelected = null) => {
    if (!statusGroupId || statusGroupId > Object.keys(StatusGroupType).length) {
      return;
    }
    let statusByGroup = _.find(StatusGroupType, {id: filterGroupSelected || statusGroupId}).status;

    let saveSearchQueryStatusFilters = null;
    if (queryParams.filters && queryParams.filters.estado && !_.isEmpty(queryParams.filters.estado)) {
      if (queryParams.filters.estado[0].hasOwnProperty('value')) {
        saveSearchQueryStatusFilters = _.map(queryParams.filters.estado, 'value');
      } else {
        saveSearchQueryStatusFilters = queryParams.filters.estado;
      }
    }

    let intersectedStatus = saveSearchQueryStatusFilters ? statusByGroup.filter(status => saveSearchQueryStatusFilters.indexOf(status) !== -1) : [];
    let resultFilters = {...queryParams.filters};

    if (module !== MODULES.ID_EMPLOYEE_LINE && module !== MODULES.ID_ON_BOARDING) {

      resultFilters.estado = [];
      if (saveSearchQueryStatusFilters) {
        intersectedStatus.map(status => resultFilters.estado.push({
          value: status,
          label: status,
          name: 'estado',
          id: 'estado-' + status,
          filter: undefined
        }));
      } else {
        statusByGroup.map(status => resultFilters.estado.push({
          value: status,
          label: status,
          name: 'estado',
          id: 'estado-' + status,
          filter: undefined
        }));
      }
    }

    if (filterGroupSelected) {
      fetchProcesses(resultFilters, queryParams.range, queryParams.sort, module, filtersGroups);
    } else {
      getInboxData(resultFilters, [0, 1], ["", ""], module)
          .then((res) => {
            if (res.data.length === 0) {
              initOrFetchInboxOnSaveSearchItemPage(queryParams, filtersGroups, statusGroupId + 1, isInit);
            } else {
              if (isInit) {
                initInbox(resultFilters, queryParams.range, queryParams.sort, module, filtersGroups)
              } else {
                fetchProcesses(resultFilters, queryParams.range, queryParams.sort, module, filtersGroups)
              }
              setFilterGroup(statusGroupId);
              setLastFilterGroupValue(statusGroupId);
            }
          });
    }
  }*/

  const getMergedQueryParams = () => {
    let queryParams = getQueryParams();
    let mergedFilters = {...saveSearchQuery.filters, ...queryParams.filters}
    return {filters: mergedFilters, range: queryParams.range, sort: queryParams.sort};
  }

  const updateData = () => {
    // TODO: se cambia a la página siguiente con el id correspondiente pero al volver al inbox carga la pag 0, no usa del state
    const filtersGroups = showFilterGroup ? filters : null
    const queryParams = isSaveSearchItemPage ? getMergedQueryParams() : getQueryParams();
    if (isSaveSearchItemPage) {
      if (inboxModule !== module) {
          initInbox(queryParams.filters, queryParams.range, queryParams.sort, module, filtersGroups, true)
      } else {
        let resultFilters = queryParams.filters
        if (module === MODULES.ID_EMPLOYEE_LINE && saveSearchData.id === parseInt(slug) && queryParams.filters !== {}) {
          if (filterGroupSelected && filterGroupSelected !== -1) {
            resultFilters['state.name'] = i18n.status[filterGroupSelected];
          }
          fetchProcesses(queryParams.filters, queryParams.range, queryParams.sort, module, filtersGroups, true, filterGroupSelected)
        } else if (module !== MODULES.ID_EMPLOYEE_LINE){
          fetchProcesses(queryParams.filters, queryParams.range, queryParams.sort, module, filtersGroups, true, filterGroupSelected)
        }
      }
    } else {
      if (!isOfferItemPage || (isOfferItemPage && filterGroupSelected && filterGroupSelected !== -1)) {
        if (inboxModule !== module) {
          initInbox(queryParams.filters, queryParams.range, queryParams.sort, module, filtersGroups)
        } else {
          fetchProcesses(queryParams.filters, queryParams.range, queryParams.sort, module, filtersGroups)
        }
      }
    }
  }

  const updateQuery = () => {
      setQuery(getQueryParams())
  }

  const updateQueryIfFiltersWasChanged = () => {
    let newQueryParams = getQueryParams()

    //Hemos quitado la condición del if porque no filtraba en el caso de que
    // quitaramos los filtros de uno en uno hasta 0 o los quitaramos todos de golpe
    if ((isSaveSearchItemPage && (_.isEqual(newQueryParams, saveSearchQuery) || lastFilterGroupValue !== filterGroupSelected))
      || (!_.isEqual(query, newQueryParams) ) ) {
      updateQuery()
    }
  }

  const updateDataQuery = () => tableRef && tableRef.current && tableRef.current.onQueryChange()

  const checkRow = event => setRowsSelected(event)

  const handleCloseChangeStatusModal = () => setOpenChangeStatusModal(false)
  const handleOpenChangeStatusModal = () => setOpenChangeStatusModal(true)

  const handlePostFavorite = (isFavorite, ids) => {
    const rows = ids || rowsSelected
    if (_.size(rows) > 0) {
      const ids = _.map(rows, 'id')
      const queryParams = getQueryParams()
      postFavorites(ids, isFavorite, queryParams, module)
    }
  }

  const handleMarkAsRead = () => {
    if (_.size(rowsSelected) > 0) {
      const ids = _.map(rowsSelected, 'id')
      const queryParams = getQueryParams()
      markAsRead(ids, queryParams, module)
    }
  }

  const handleUnmarkAsRead = () => {
    if (_.size(rowsSelected) > 0) {
      const ids = _.map(rowsSelected, 'id')
      const queryParams = getQueryParams()
      unmarkAsRead(ids, queryParams, module)
    }
  }

  const clearRowsSelected = () => {
    setRowsSelected([])
    tableRef.current && tableRef.current.onAllSelected(false)
  }

  const onSubmitChangeStatus = (status, date) => {
    setOpenChangeStatusModal(false)
    if (_.size(rowsSelected) > 0) {
      const ids = _.map(rowsSelected, 'id')
      const queryParams = getQueryParams()
      changeStatus(ids, status, queryParams, date, module, filterGroupSelected ? filters : null)
      clearRowsSelected()
    }
  }

  const refetchInboxesQueriesBySection = (extraQueries = []) => {
    let queriesToRefetch = [`${module}:${QUERY_KEYS.INBOX}`,`${module}:${QUERY_KEYS.FAVORITES}`, `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}`];
    queriesToRefetch = queriesToRefetch.concat(extraQueries);
    refetchQueriesByKeys(queriesToRefetch);
  }

  const onSubmitChangeFunnelStatus = (status) => {
    if (_.size(rowsSelected) > 0) {
      const ids = _.map(rowsSelected, 'id');
      let data = [];
      let queriesToRefetch = [];
      let queriesToInvalidate = [];
      ids.map(id => {
        data.push({id: id, status_id: status.id, date: iso8601Format()});
        queriesToRefetch.push(`${module}:${QUERY_KEYS.PROFILE}:${id}`)
        queriesToInvalidate.push(`${QUERY_KEYS.OBSERVATIONS}:${id}`)
      });

      mutationUpdateStatesSelectionFunnel.mutateAsync(data, {
        onSuccess: () => {
          refetchInboxesQueriesBySection(queriesToRefetch);
          invalidateQueriesByKeys(module, queriesToInvalidate);
        },
      });
      clearRowsSelected();
    }
  }

  const onSubmitChangeTags = (tags) => {
    setOpenChangeTagsModal(false)
    if (_.size(rowsSelected) > 0) {
      const ids = _.map(rowsSelected, 'id')
      const queryParams = getQueryParams()
      changeTags(ids, tags, queryParams, module, filterGroupSelected ? filters : null)
      clearRowsSelected()
    }
  }

  const handleRowClick = (e, row) => {
    localStorage.setItem('backLocation', location.pathname + '?keepFilters=1');
    history.push(`/profile/${module}/${row.id}`)
  }

  const handleChangePage = page => {
    setPage(page)
  }

  const handleChangeRowsPerPage = perPage => {
    setPage(0)
    setPerPage(perPage)
  }

  const handleOrderChange = (column, orderDirection) => {
    setPage(0)
    setSort(orderDirection ? [column.field, orderDirection] : ["", ""]);
  }

  const handleFilterSelected = selected => {
    if (filterGroupSelected !== selected) {
      if (isOfferItemPage) {
        setOfferFilterGroupSelected(selected);
      } else {
        setLastFilterGroupValue(filterGroupSelected);
        setFilterGroup(selected);
      }
    }
  }

  const renderState = (field, rowData, statusTypes, styles) => {
    const fieldSelected = _.get(rowData, field)
    if (!fieldSelected) return null
    const status = _.find(statusTypes, (item) => item.id.toString() === fieldSelected.toString())
    return <MTText style={{minWidth: '80px', ...styles}} value={status ? i18n.status[status.id] : ''}
                   color={status ? status.color : null}/>
  }

  const renderTags = (field, rowData) => {
    return _.map(rowData.tags, tag => {
      return (
        <Grid item>
          <Tag tag={tag} shortVersion/>
        </Grid>
      )
    })
  }

  const renderRecovered = (isPullAccepted) => {
    let pullIcon;

    switch (isPullAccepted) {
      case 1:
        pullIcon = <i className="fa fa-arrow-circle-up isPullAcceptedIcon" aria-hidden="true" style={{color: 'green'}}></i>;
        break;
      case 0:
        pullIcon = <i className="fa fa-clock-o isPullAcceptedIcon" aria-hidden="true" style={{color: 'grey'}}></i>;
        break;
      case -1:
        pullIcon = <i className="fa fa-2x fa-times-circle isPullAcceptedIcon" aria-hidden="true" style={{color: 'red'}}></i>;
        break;
      default:
        break;
    }

    return pullIcon;
  }
  const getCurrentIntegration = () => {
    if (auth && INTEGRATIONS) {
      let currentIntegration = _.find(INTEGRATIONS, {event_type: auth.event_type})
      return currentIntegration ? currentIntegration : null;
    }
    return null
  }

  const onSubmitAnnouncement = (data) => {
    if (announcement && _.size(announcement.rowsSelected) > 0) {
      let filteredAnnouncement = announcement.rowsSelected.filter(item => ![StatusType.HIRED, StatusType.DISCARDED, StatusType.DISCARDED_PHASE_2].includes(item.estado));
      const ids = _.map(filteredAnnouncement, 'id');

      postOrPatchCitations({data, ids: ids}).then(() => {
        setAnnouncement(false)
        let status_id = getCurrentIntegration() ? StatusType.CITED : StatusType.TO_BE_CONFIRMED;
        changeStatus(ids, status_id, getQueryParams(), moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), module, filterGroupSelected ? filters : null);
        clearRowsSelected();
        announcement.updateQuery(true);

      })
      announcement.clearRowsSelected()
    }
  }


  const onSubmitRequireDoc = (data) => {
    if (requireDoc && _.size(requireDoc.rowsSelected) > 0) {
      const ids = _.map(requireDoc.rowsSelected, 'id')
      requireDocumentation(ids, data).then(() => {
        requireDoc.updateQuery(true)
        setRequireDoc(false)
      })
      requireDoc.clearRowsSelected()
    }
  }

  const handleSubmitMailCustom = mailCustom => {
    if (_.size(rowsSelected) > 0) {
      const ids = _.map(rowsSelected, 'id')
      sendEmailCustom(ids, mailCustom.subject, mailCustom.message, module).then(() => clearRowsSelected())
    }
  }

  const getInitials = fullName => {

    function getInitial(name){
      return name && name.length ? name[0].toUpperCase() : '';
    }

    const fullNameSplit = fullName ? fullName.split(' ') : false;
    if (fullNameSplit && fullNameSplit.length > 1) {
      return getInitial(fullNameSplit[0]) + getInitial(fullNameSplit[1]);
    }
    return '';
  }

  const renderField = (field, rowData, statusTypes) => {
    switch (field) {
      case 'tags':
        return (
          renderTags('tags', rowData)
        )
      case 'is_favorite':
        return (
          <Grid justifyContent="start" container>
            <Grid item sm={4}>
              <FavouriteIcon
                // style={{ textAlign: 'center' }}
                favorite={_.get(rowData, 'is_favorite')}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!isFetching) handlePostFavorite(!_.get(rowData, 'is_favorite'), [rowData])
                }}
              />
            </Grid>
            <Grid className="tagsColumn" container alignItems='center' justifyContent='start' item sm={8}>
              {renderTags('tags', rowData)}
            </Grid>
          </Grid>)
      case 'search_candidature_name':
        return (
          <Grid container justifyContent='start' className={["wrapImageName", getReadClass(rowData)]}>
            <Grid item style={{position: "relative"}}>
              <MTImage foto={_.get(rowData, 'foto')} inicials={getInitials(_.get(rowData, field))}
                       pullIcon={hasPullFeature && rowData.hasOwnProperty('is_pull_accepted')
                         ? renderRecovered(rowData.is_pull_accepted) : null}/>
            </Grid>
            <Grid item>
              <SearchCandidatureName value={_.get(rowData, field)}/>
              {_.get(rowData, 'candidatura') ? <CandidatureText value={_.get(rowData, 'candidatura')}/> : null}
            </Grid>
          </Grid>
        )
      case 'estado':
      case 'state.id':
        return renderState(field, rowData, statusTypes, getReadClass(rowData) === 'was_read' ? {fontWeight: 400} : {})
      case 'employeeHasProcess.employee.phone':
        return <SubtextStyled value={_.get(rowData, field)} className={getReadClass(rowData)}/>
      case 'disponibilidad':
      case 'update_at':
      case 'create_at':
      case 'hiringDate':
      case 'inscripcion':
        return <MTText value={moment(_.get(rowData, field)).format('DD/MM/YYYY')} className={getReadClass(rowData)}/>
      case 'createdAt':
        return (
          <>
            <MTText value={moment(_.get(rowData, field)).format('DD/MM/YYYY')} className={getReadClass(rowData)}/>
          </>
        ) // <MTText value={moment(_.get(rowData, field)).format('HH:mm')} className={getReadClass(rowData)}/>
      case 'profile':
      case 'skills':
        return <MTLinearPercentStyled value={Math.round(_.get(rowData, field))} className={getReadClass(rowData)}/>
      case 'cv':
        return <MTExporterButton cv={_.get(rowData, field)}/>
      case 'foto':
        return null
      case 'matching':
        return <MTCircularPercent fontSize="15px" width="54px" height="54px" thickness={4.5} left={true}
                                  value={Math.round(_.get(rowData, field))} valid={_.get(rowData, 'apto', undefined)} className={getReadClass(rowData)}/>
      case 'video':
        return <MTVideo videoUrl={_.get(rowData, field)}/>
      case 'centro_trabajo':
        return (
          <LimitedDefaultCol title={_.get(rowData, field)} value={_.get(rowData, field)}
                             className={getReadClass(rowData)}/>
        )
      case 'offer_has_candidatures.offer.state':
        return <OfferStateImageStyled><OfferStateImage
          state={_.get(searchCurrentOfferHasCandidature(rowData, _.get(offer, 'id')), 'state')}/></OfferStateImageStyled>
      default:
        if (typeof _.get(rowData, field) === 'object' || _.get(rowData, field) === 'array') {
          return null
        }
        if (typeof(_.get(rowData, field)) == 'string') {
          if(_.get(rowData, field).length === 0){
            return <MTText value={'-'} className={getReadClass(rowData)}/>
          }
          if (_.get(rowData, field).match(/\d{2}\/\d{2}\/\d{4} \d\d:\d\d/)) {
            return <MTText value={_.get(rowData, field).split(' ')[0]} className={getReadClass(rowData)}/>
          }
          if (_.get(rowData, field).match(/\d{4}-\d{2}-\d{2}T\d\d:\d\d/)) {
            return <MTText value={moment(_.get(rowData, field)).format('DD/MM/YYYY')} className={getReadClass(rowData)}/>
          }
        }
        return <LimitedDefaultCol value={_.get(rowData, field)} className={getReadClass(rowData)}/>
    }
  }

  const putFavoriteColumnFirst = objectWithColumnsMaped => {
    const first = "is_favorite";
    return objectWithColumnsMaped.sort(function (x, y) {
      return x.field === first ? -1 : y.field === first ? 1 : 0;
    });
  }

  const getColumns = (columns, statusTypes) => {
    if (columns && columns.length > 0) {
      let objectWithColumnsMaped = _.map(columns, item => {
        /* Remove column foto from table */
        if (item.remote_name !== 'foto') {
          switch (item.remote_name) {
            case 'search_candidature_name':
              return ({
                title: item.name,
                field: item.remote_name,
                headerStyle: {
                  width: '280px',
                },
                render: rowData => renderField(item.remote_name, rowData, statusTypes)
              })
            case 'foto':
              return null
            case 'direccion':
              return ({
                title: item.name,
                field: item.remote_name,
                headerStyle: {
                  width: '250px',
                },
                render: rowData => renderField(item.remote_name, rowData, statusTypes)
              })
            case 'offer_sent':
              if (!hasFeaturePush) {
                return null;
              }
              return ({
                title: i18n.processesTable.offer_sent,
                field: item.offer_has_candidatures,
                render: rowData => {
                  let offerStates = {};
                  rowData.offer_has_candidatures.map(val => {
                    if (!offerStates.hasOwnProperty(val.state)) {
                      offerStates[val.state] = 0
                    }
                    offerStates[val.state] +=1
                  });

                  let interested = offerStates[OfferCandidatureStatusType.INTERESTED];
                  let not_interested = offerStates[OfferCandidatureStatusType.NOT_INTERESTED];
                  let unanswered = offerStates[OfferCandidatureStatusType.UNANSWERED];

                  return <span style={{display: "flex", gap: 12}}>
                      {interested && interested > 0 && <Badge badgeContent={String(interested)} color="primary"><OfferStateImage
                        state={OfferCandidatureStatusType.INTERESTED} bigSize={true}></OfferStateImage></Badge>}
                      {not_interested && not_interested > 0 && <Badge badgeContent={String(not_interested)} color="primary"><OfferStateImage
                        state={OfferCandidatureStatusType.NOT_INTERESTED} bigSize={true}></OfferStateImage></Badge>}
                      {unanswered && unanswered > 0 && <Badge badgeContent={String(unanswered)} color="primary"><OfferStateImage
                        state={OfferCandidatureStatusType.UNANSWERED} bigSize={true}></OfferStateImage></Badge>}
                  </span>;
                }
              });
            default:
              return ({
                title: item.name,
                field: item.remote_name,
                render: rowData => renderField(item.remote_name, rowData, statusTypes)
              })
          }
        }
        /* Remove undefined generate by foto */
      }).filter(item => item !== undefined)

      return (
        putFavoriteColumnFirst(objectWithColumnsMaped)
      );
    }
    return []
  }

  let toolbarOptions = []
  if (!isOnlyReader) {
    toolbarOptions = [
      {
        id: 'markAsRead',
        label: i18n.processesTable.markAsRead,
        onClick: () => {
          handleMarkAsRead()
          clearRowsSelected()
        }
      },
      {
        id: 'unmarkAsRead',
        label: i18n.processesTable.unmarkAsRead,
        onClick: () => {
          handleUnmarkAsRead()
          clearRowsSelected()
        }
      },
      {
        id: 'markFavorite',
        label: i18n.processesTable.markFavorite,
        onClick: () => {
          handlePostFavorite(true)
          clearRowsSelected()
        }
      },
      {
        id: 'removeFavorites',
        label: i18n.processesTable.removeFavorites,
        onClick: () => {
          handlePostFavorite(false)
          clearRowsSelected()
        }
      },
      module !== MODULES.ID_EXIT_INTERVIEW && showChangeStatus ? {
        id: 'changeStatus',
        label: i18n.processesTable.changeStatus,
        onClick: () => {
          handleOpenChangeStatusModal()
        }
      } : null,
      module === MODULES.ID_TALENT_ACQUISITION ? {
        id: 'announcement',
        label: i18n.peopleTable.quoteCall,
        onClick: ({...props}) => {
          setAnnouncementModal(true)
          setAnnouncement(props)
        }
      } : null,
      module === MODULES.ID_TALENT_ACQUISITION ? {
        id: 'requireDoc',
        label: i18n.peopleTable.requireDoc,
        onClick: ({...props}) => {
          setRequireDocModal(true)
          setRequireDoc(props)
        }
      } : null,
      module !== MODULES.ID_EXIT_INTERVIEW && tagsTypes && tagsTypes.length > 0 ? {
        id: 'changeTags',
        label: i18n.processesTable.changeTags,
        onClick: () => {
          setOpenChangeTagsModal(true)
        }
      } : null,
      module !== MODULES.ID_EXIT_INTERVIEW && notificationMultipleEnabled && {
        id: 'sendNotification',
        label: i18n.modalMailCustom.quoteCall,
        onClick: () => {
          setOpenMailCustomModal(true)
        }
      },
      hasFeaturePush && match.path === routes.offerCandidatures ? {
        id: 'delete',
        label: i18n.saveSearchesTable.delete,
        onClick: () => {
          let offerId = location && location.pathname ? location.pathname.split('/') : '';
          offerId = offerId.length > 0 ? offerId.pop() : '';
          if (offerId !== '') {
            deletePushOffer(offerId).then(() => {
              history.push(routes.pushOffer);
            });
          }
        }
      } : null,
      ...actions
    ];
  }

  const tableOptions = {
    paginationType: 'normal',
    emptyRowsWhenPaging: false,
    loadingType: 'linear',
    selection: true,
    pageSizeOptions: [20, 50, 100, 200],
    pageSize: perPage,
  }

  let mainStatusTypes

  if (match.path === routes.offerCandidatures) {
    mainStatusTypes = _.map(offerCandidatureStatusType, status => {
      return {id: status, name: i18n.offerStates[status], total: offer ? offer[status] : 0}
    })
  } else {
    mainStatusTypes = _.map(_.filter(statusTypes, {is_main_type: true}), status => {
      return {id: status.id, name: status.name, total: initialDataInbox[`total_status_${status.id}`]}
    })
  }

  const exportFilter = {...filters}
  if (rowsSelected && rowsSelected.length > 0) exportFilter.id = _.map(rowsSelected, item => item.id)

  const onItemClick = (id) => {
    _.find(toolbarOptions, {id}).onClick({
      rowsSelected,
      updateQuery: updateData,
      clearRowsSelected
    })
  }

  const getTotalrowsSelected = () => {
    return rowsSelected ? _.map(rowsSelected, (item) => item.id).length : 0;
  }

  const getStatusOptions = () => {
    if (user_email && user_email.includes(autoStatusDomains())) {
      if (!_.isEmpty(statusTypes)) {
        return statusTypes;
      }
    } else {
      if (!_.isEmpty(manualStatusTypes)) {
        return manualStatusTypes;
      }
    }
    return statusOptions();
  }

  const tableComponents = {
    Container: props => <div {...props} />,
    Toolbar: () => (
      <MTToolbar
        module={module}
        onSubmitChangeStatus={onSubmitChangeStatus} statusTypes={getStatusOptions()}
        onSubmitChangeFunnelStatus={onSubmitChangeFunnelStatus} funnelStatusTypes={statusFunnelTypes?.data ?? []}
        totalRowsSelected={totalRowsSelected}
        totalRows={forceTotal ? forceTotal : total}
        onItemClick={onItemClick}
        variant={showFilterGroup ? 'filterGroup' : null}
        options={toolbarOptions}
        disabled={_.isEmpty(rowsSelected)}
        isPushPage={hasFeaturePush && match.path === routes.offerCandidatures}
      >
        {showFilterGroup ? (
          <TableFilters
            module={module}
            total={
              forceTotal ? forceTotal :
                (initialDataInbox && !offer
              ? initialDataInbox.total
              : (offer ? offer.interested + offer.not_interested + offer.unanswered : 0)
                )
            }
            values={mainStatusTypes}
            selected={filterGroupSelected}
            onFilterSelected={handleFilterSelected}
          />
        ) : ''}
        <Grow/>
        {/*<ExportButton
          module={module}
          fields={reportFields}
          filters={newFilters}
          sort={sort}
          ids={_.map(rowsSelected, (item) => item.id)}
        />        */}
        {/*hasFeaturePush && <OfferInboxButton
          filters={newFilters}
          sort={sort}
          ids={_.map(rowsSelected, (item) => item.id)}
          sms={client ? client.sms_available : false}
        />*/}
      </MTToolbar>
    )
  }

  const dataQuery = async () => {
    try {
      const totalCount = forceTotal ? forceTotal : total

      if ((!data || data.length <= 0) || (!columns || columns.length <= 0)) return {data: [], page: 0, totalCount: 0}
      return {data: data, page, totalCount}
    } catch (e) {
      return {data: [], page: 0, totalCount: 0}
    }
  }

  const columns = getColumns(dataFields, statusTypes)
  /*
    const handleCloseViewModal = () => {
      updateQuery()
      setRowsSelected(false)
    }*/

  return (
    <FavoritesTableStyled>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        isLoading={isFetching}
        data={dataQuery}
        options={tableOptions}
        onSelectionChange={checkRow}
        components={tableComponents}
        localization={i18n.tableLocalization}
        onRowClick={handleRowClick}
        draggable={false}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onOrderChange={(orderedColumnId, orderDirection) => handleOrderChange(columns[orderedColumnId], orderDirection)}
      />
      {module !== MODULES.ID_EXIT_INTERVIEW && <ModalChangeStatus
        open={openChangeStatusModal}
        handleClose={handleCloseChangeStatusModal}
        rowsSelected={rowsSelected}
        onSubmit={onSubmitChangeStatus}
        options={statusTypes}
      />}
      {module !== MODULES.ID_EXIT_INTERVIEW && tagsTypes && tagsTypes.length > 0 &&
        <ModalChangeTags
          open={openChangeTagsModal}
          handleClose={() => setOpenChangeTagsModal(false)}
          rowsSelected={rowsSelected}
          onSubmit={onSubmitChangeTags}
        />
      }
      {module !== MODULES.ID_EXIT_INTERVIEW && <ModalMailCustom
        open={isOpenMailCustomModal}
        handleClose={() => setOpenMailCustomModal(false)}
        onSubmit={handleSubmitMailCustom} {...props}
      />}
      {module !== MODULES.ID_EXIT_INTERVIEW && <ModalAnnouncement
        open={announcementModal}
        handleClose={() => setAnnouncementModal(false)}
        onSubmit={onSubmitAnnouncement}
        sms={client ? client.sms_available : false}
        data={rowsSelected}
      />}
      {module !== MODULES.ID_EXIT_INTERVIEW && <ModalRequireDoc
        open={requireDocModal}
        handleClose={() => setRequireDocModal(false)}
        onSubmit={onSubmitRequireDoc}
      />}
      {/*rowViewRender &&
        <ModalView open={rowSelected} title={'Profile'} handleClose={handleCloseViewModal}>
          {rowSelected && rowViewRender(rowSelected)}
        </ModalView>
      */}
    </FavoritesTableStyled>
  )
}

CustomTable.defaultProps = {
  title: 'Inbox',
  showChangeStatus: false,
  actions: [],
  resetFiltersOnLoad: true,
  notificationMultipleEnabled: false,
}

CustomTable.propTypes = {
  initInbox: PropTypes.func,
  filters: PropTypes.object,
  defaultFilters: PropTypes.object,
  filterGroupSelected: PropTypes.string,
  data: PropTypes.array,
  statusTypes: PropTypes.array,
  tagsTypes: PropTypes.array,
  total: PropTypes.number,
  history: PropTypes.object,
  unmarkAsRead: PropTypes.func,
  postFavorites: PropTypes.func,
  markAsRead: PropTypes.func,
  changeStatus: PropTypes.func,
  setFilterGroup: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  showFilterGroup: PropTypes.bool,
  dataFields: PropTypes.array,
  dataInbox: PropTypes.object,
  fetchProcesses: PropTypes.func,
  module: PropTypes.string,
  reportFields: PropTypes.array,
  title: PropTypes.string,
  showChangeStatus: PropTypes.bool,
  actions: PropTypes.array,
  initialDataInbox: PropTypes.object,
  tablePage: PropTypes.number,
  tableSort: PropTypes.array,
  tableSetPage: PropTypes.func,
  tableSetSort: PropTypes.func,
  tablePerPage: PropTypes.number,
  tableSetPerPage: PropTypes.func,
  queryOptions: PropTypes.object,
  ready: PropTypes.bool,
  setReady: PropTypes.func,
  onRowClick: PropTypes.func,
  rowViewRender: PropTypes.func,
  fetchFilterGroupInbox: PropTypes.func,
  inboxModule: PropTypes.string,
  offer: PropTypes.object,
  changeTags: PropTypes.func,
  postCitations: PropTypes.func,
  client: PropTypes.object,
  notificationMultipleEnabled: PropTypes.bool
}

export default CustomTable
